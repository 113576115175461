<template>
    <div >
        <section id="proyectos-progreso">
            <div class="container">
              <!-- presentacion -->
              <div class="row vh-100">
                <div class="col-12 justify-content-center text-center">
                <p class="mb-1 space-grotesk fw-light my-4">We Present our</p>
                <h1 class="ibm-plex-sans fw-bold my-4">Projects In Progress</h1>
              <img
                src="@/assets/images/common/bullet.svg"
                alt="bullet"
                class="img-fluid mt-3"
                />
              </div>
                  <!-- lista proyectos -->
              <div class="col-12">
              <div class="row scroll-horizontal">
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-uno shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('tren-interurbano')" class="cursor">
                  <ArrowService service="Interurban Train Mexico - Toluca" />
                </a>
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-dos shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('suministro')" class="cursor">
                  <ArrowService service="Tren Maya Cable Supply" />
                </a>
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-tres shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Tramo-5-sur')" class="cursor">
                  <ArrowService service="Catenaria del tren Maya" />
                </a>
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-cuatro shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Lecheria-AIFA')" class="cursor">
                  <ArrowService service="Energy System Suburbano <br/> Lecheria - AIFA" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-cinco shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Tramo-5-Norte')" class="cursor">
                  <ArrowService service="Catenaria section 5 instalation" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-seis shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Tramos-5N-6')" class="cursor text-wrap">
                  <ArrowService service="Remote Control of Seccionadores de Catenaria" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-siete shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('slt')" class="cursor text-wrap">
                  <ArrowService service="359 SLT Congestion Solution of GCR Noroccid Nte. Transmission Links Phase 1" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11 me-lg-4 me-sm-2">
                  <div class="col-12 progreso-ocho shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('Tramo-6')" class="cursor">
                  <ArrowService service="Catenaria section 5 instalation" />
                </a>  
                </div>
                <div class="col-lg-4 col-md-6 col-11">
                  <div class="col-12 progreso-nueve shadow-sm mb-4"></div>
                  <a @click="scrollBehavior('slt-fase-2')" class="cursor text-wrap">
                  <ArrowService service="359 SLT Link Congestion Solution <br>Transmission GCR Northwest North Phase 2 Stage 3" />
                </a>  
                </div>
              </div>
              </div>
              </div>
            </div>
        </section>
        
        <section id="tren-interurbano">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Interurban Train Mexico - Toluca</h1>
            </div>
            </div>
            <div class="row h-100">
              <!-- galeria -->
               <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <!-- imagen temporal -->
                 <div id="carouselinterurbano"class="carousel slide carousel-fade " data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/01.webp" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/02.webp" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/03.webp" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/04.webp" class="d-block w-100">
                    </div>
                    <div class="carousel-item">
                      <img src="@/assets/images/inprogress/interurbano-mex-tol/05.webp" class="d-block w-100">
                    </div>
                  </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="2"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="3"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselinterurbano"
                    data-bs-slide-to="4"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                 </div>
               </div>
               <div class="col descripcion">
                 <div class="col m-5">
                  <h3>Project Description</h3>
                  <p>The purpose of the contract is the execution of part of the superstructure, including the track, 
                    catenary, and the electrical system of the entire project:
                  </p>
                  <ul>
                    <li>Track construction, including various track devices, switch machines, expansion joints.</li>
                    <li>Execution of the catenary and energy supply of equipment on the line and remote control of energy.</li>
                    <li>Construction of Traction Substations (2) of 40 MVA, with connections at 230 kV high voltage and 23 kV medium voltage.</li>
                    <li>Construction of Lighting and Power Substations at 23 kV, for passenger stations (6), workshops and depots (1+2), technical buildings (2), and tunnel galleries (5).</li>
                  </ul>
                  <div class="row align-content-center justify-content-center">
                    <div class="col-lg-5 col-8 align-content-center mt-3">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_SCT.png"
                        class="img-fluid">
                    </div>
                  </div>
                 </div>
               </div>
               <!-- informacion -->
            </div>
          </div>
        </section>
        <section id="suministro">
          <div class="container">
            <div class="row">
            <div class="bg-dark-50-entia">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Tren Maya Cable Supply</h1>
            </div>
            <div class="row">
              <!-- galeria -->
               <div class=" my-4 col-12 col-lg-6 align-content-center justify-content-center">
                <!-- imagen temporal -->
                  <div id="carouselsuministro" class="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/01.webp" class="d-block w-100">
                      </div>
                      <div class="carousel-item">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/02.webp" class="d-block w-100">
                      </div>
                      <div class="carousel-item">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/03.webp" class="d-block w-100">
                      </div>
                      <div class="carousel-item">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/04.webp" class="d-block w-100">
                      </div>
                      <div class="carousel-item">
                        <img src="@/assets/images/inprogress/suministro-tren-maya/05.webp" class="d-block w-100">
                      </div>
                    </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="2"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="3"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselsuministro"
                    data-bs-slide-to="4"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                  </div>
               </div>
               <div class="col-12 col-lg-6 p-0 descripcion">
                 <div class="col m-5">
                  <h3>Project Description</h3>
                  <p>The purpose of the contract is the supply of various cables and insulators for:
                  </p>
                  <ul>
                    <li>Construction of the Maya Train, Section 5 North, from Cancun to Playa del Carmen North, Quintana Roo, Energization and Electromechanical Work (Front 4).</li>
                    <li>Construction of the Maya Train, Section 6, Tulum-Chetumal, Quintana Roo, Section 7, from Bacalar, Quintana Roo to Escárcega, Campeche. Energization and Electromechanical Work (Front 8).</li>
                    <li>Supply of insulators: single-pole load disconnectors, bipolar load disconnectors.</li>
                    <li>Cable supply: cable for disconnectors.</li>
                  </ul>
                  <div class="row align-content mt-2 text-center justify-content-center">
                    <div class="col-lg-6 col-10 align-content-center">
                      <img
                        src="@/assets/images/inprogress/logos/LOGO_SEDENA.png"
                        class="img-fluid"
                        >
                    </div>
                  </div>
                 </div>
               </div>
               <!-- informacion -->
            </div>
          </div>
          </div>
        </section>
        <section id="Tramo-5-sur">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
              <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Catenaria del Tren Maya Section 5 South - subsection C</h1>
            </div>
            </div>
            <div class="row">
              <!-- galeria -->
               <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <div id="carouseltramo5sur" class="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active"><img src="@/assets/images/inprogress/tramo-5-sur/01.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/tramo-5-sur/02.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/tramo-5-sur/03.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/tramo-5-sur/04.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/tramo-5-sur/05.webp" alt="" class="img-fluid"></div>
                  </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="2"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="3"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5sur"
                    data-bs-slide-to="4"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                </div>
               </div>
               <div class="col descripcion">
                <div class="col m-5">
                  <h3>Project Description</h3>
                  <p>The purpose of the contract is the supply and installation of the Catenary System for Section 5 South of the Maya Train:
                  </p>
                  <br>
                  <p>Poles, station porticos, testing and installation of brackets, laying and installation of wires, cables, assembly, 
                    safety signals, insulators, devices, catenary sectioning adjustment, development of commissioning tests, and 
                    legalization of all installations.
                  </p>
                  <h3>The Project in figures</h3>
                  <div class="row align-content-center text-center justify-content-center">
                    <div class="col-lg-5 col-7 mt-3 align-content-center">
                      <h1 class="m-0 fw-bold">20 KM</h1>
                      <h3 class="fw-bold fs-4">SECTION 5 SOUTH</h3>
                    </div>
                    <div class="col-5 col-lg-3 mt-3 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_ICA.png"
                        class="img-fluid">
                    </div>
                  </div>
                </div>
               </div>
            </div>
          </div>
        </section>
        <section id="Lecheria-AIFA">
          <div class="container">
            <div class="row">
            <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Energy System and SETE Suburban Extension Lechería-AIFA</h1>
            </div>
            </div>
            <div class="row">
              <!-- galeria -->
               <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <div id="carouselaifa" class="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active"><img src="@/assets/images/inprogress/energia-sete/01.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/energia-sete/02.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/energia-sete/03.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/energia-sete/04.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/energia-sete/05.webp" alt="" class="img-fluid"></div>
                  </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="2"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="3"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouselaifa"
                    data-bs-slide-to="4"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                </div>
               </div>
               <div class="col descripcion">
                <div class="col m-5">
                  <h3>Project Description</h3>
                  <p>The purpose of the contract is the execution of the works corresponding to the following systems: </p>
                  <ul>
                    <li>Energy Systems, including: 
                      <ul>
                        <li>Auxiliary Substations for Stations, Inspection Zone, and CETRAMs:
                          <br>
                          Supply, Assembly, Testing, and Commissioning of the equipment that integrates the substations. 
                        </li>
                        <li>
                          23 kV Ring - Supply, laying, and testing of a circuit and one conductor per phase with AI 18/30 
                          kV RHZ1-OL 1x240mm<sup>2</sup> cable.
                        </li>
                      </ul>
                    </li>
                    <li>Traction Substation (SETE): Supply, Assembly, Testing, and Commissioning of the equipment that 
                      integrates the SETE (except GIS and power transformer, supplied by CAFTE).
                    </li>
                    <li>Depressed Transformer Center: Supply and assembly of equipment.                   
                    </li>
                    <li>Auxiliary Substations: 12 in total.
                      <ul>
                        <li>Stations 1,2,3,4,5,6, AIFA, Inspection Zone, Cetram 1,2,3, and 4.</li>
                      </ul>
                    </li>
                    <li>23kV Ring: 24km; Medium Voltage Cable connecting the substations.
                    </li>
                    <li>Depressed Transformer Center (Depressed CT).
                    </li>
                  </ul>
                  <h3>The Project in figures</h3>
                  <div class="row align-content-center text-center justify-content-center">
                    <div class="col-lg-5 align-content-center col-5">
                      <h1 class="m-0 fw-bold">23.6 KM</h1>
                    </div>
                    <div class="col-lg-5 col-7 my-3 align-content-center justify-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_CFA.png"
                        class="img-fluid">
                    </div>
                  </div>
                </div>
               </div>
            </div>
          </div>
        </section>
        <section id="Tramo-5-Norte">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Installation if the Catenaria for front 4 section 5 North of Tren Maya</h1>
            </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <div id="carouseltramo5norte" class="carousel slide carousel-fade" data-bs-ride="carousel">
                  <div class="carousel-inner">
                    <div class="carousel-item active"><img src="@/assets/images/inprogress/catenaria-frente-4/01.webp" alt="" class="img-fluid"></div>
                    <div class="carousel-item"><img src="@/assets/images/inprogress/catenaria-frente-4/02.webp" alt="" class="img-fluid"></div>
                  </div>
                  <div class="carousel-indicators">
                    <button
                    type="button"
                    data-bs-target="#carouseltramo5norte"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#carouseltramo5norte"
                    data-bs-slide-to="1"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  </div>
                </div>
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                  <h3>Project Description</h3>
                  <p>The purpose of the contract is the installation of the catenary for Front 4, Section 5 North of the Maya Train, including:</p>
                  <ul>
                    <li>Leasing of a bi-rail truck and crane with a capacity for 4500 kg reels.
                    </li>
                    <li>Installation of aerial return cable and cable drops every 450 m.
                    </li>
                    <li>Installation of contact wire and supporting wire.
                    </li>
                    <li>Installation of negative feeder, pendulums, and connections, as well as mechanical tension devices.
                    </li>
                    <li>Installation of insulators and various equipment.
                    </li>
                    <li>Execution period: 3 months.
                    </li>
                    <li>Leasing of a bi-rail truck.
                    </li>
                    <li>Installation of aerial return cable, contact wire, and supporting wire.
                    </li>
                  </ul>
                  <div class="row align-content-center text-center justify-content-evenly">
                    <div class="col-lg-6 col-10 my-3 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_SEDENA.png"
                        class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Tramos-5N-6">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
              <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Remote Control of Seccionadores de Catenaria from 
                Tren Maya for Sections 5N and 6</h1>
            </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <img 
                 src="@/assets/images/inprogress/telemando-seccionadores/01.webp" 
                 class="img-fluid"
                 >
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                  <h3>Project Description</h3>
                  <p>The purpose of the contract is the supply, installation, configuration, and commissioning of the Remote 
                    Control of Catenary Disconnectors for the Maya Train, Section 5 North and Section 6, including:
                  </p>
                  <ul>
                    <li>
                      Development of detailed engineering for the remote control of catenary disconnectors for both sections.
                    </li>
                    <li>
                      Configuration of electronic devices for the local operating cabinet of catenary disconnectors (PLO-CA).
                    </li>
                    <li>
                      Supply, installation, and assembly of cabinets for the remote control of catenary (PLO-CA).
                    </li>
                    <li>
                      Supply, installation, and assembly of disconnector panels, optical distributors, and various equipment.
                    </li>
                  </ul>
                  <p>Client: Secretaria de la Defensa Nacional</p>
                  <p>Development of detailed engineering for the Remote Control of Disconnectors, supply, installation, and assembly.</p>
                  <h3>The Project in figures</h3>
                  <div class="row align-content-center text-center justify-content-evenly">
                    <div class="col-lg-3 col-6 mt-3">
                      <h1 class="m-0 fw-bold">43.2 KM</h1>
                      <h3 class="m-0 fw-bold">Section 5N</h3>
                    </div>
                    <div class="col-lg-3 col-5 mt-3">
                      <h1 class="m-0 fw-bold">255 KM</h1> 
                      <h3 class="m-0 fw-bold">Section 6</h3>
                    </div>
                    <div class="col-lg-6 col-10 my-3 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_SEDENA.png"
                        class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="slt">
          <div class="container">
            <div class="row">
            <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">359 SLT Congestion Solution of GCR Noroccid Nte. Transmission Links Phase 1</h1>
            </div>
          </div>
            <div class="row">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <img 
                  src="@/assets/images/inprogress/slt/01.webp"
                  class ="img-fluid"
                >
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                <h3>Project Description</h3>
                <p>The purpose of the contract is the execution of all necessary works for the 
                  construction and installation of the project "359 SLT Solution to Congestion of 
                  Transmission Links GCR Northwest North Phase," consisting of:
                </p>
                <ul>
                  <li>Supply of equipment and materials for permanent installation.</li>
                  <li>Development and execution of civil and electromechanical engineering.</li>
                  <li>
                    Implementation of training and educational programs for the following substations:
                    <ul>
                      <li>S.E. Nuevo Casas Grandes (Casas Grandes, Chihuahua): 230 kV, 600 MVAr (2 Statcom +/- 300MVAr).</li>
                      <li>S.E. Seri (Hermosillo, Sonora): 230 kV, 400 MVAr, (2 Statcom +/-300 MVAr).</li>
                      <li>S.E. Mazatlán II (Mazatlán, Sinaloa): 400 kV, 600 MVAr (2 Statcon +/- 300 MVAr).</li>
                      <li>S.E. Primero de Mayo (Mazapil, Zacatecas): 400 kV, 600MVAr (2Statcon +/-300 MVAr).</li>
                    </ul>
                  </li>
                  <li>Development and execution of civil and electromechanical engineering.</li>
                  <li>Supply of equipment and materials for permanent installation.</li>
                  <li>Pre-operational testing.</li>
                  <li>Implementation of training and fevelopment programs.
                  </li>
                </ul>
                <h3 class="mb-3">The Project in figures</h3>
                <div class="row align-content-center text-center justify-content-center">
                  <div class="col-lg-5 col-6 align-content-center">
                      <h1 class="m-0 fw-bold">20</h1>
                      <h3>MONTHS</h3>
                    </div>
                    <div class="col-lg-3 col-5 my-3 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_CFE.png"
                        class="img-fluid">
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="Tramo-6">
          <div class="container">
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my-4 ibm-plex-sans fw-bold color-white-entia">Catenaria del Tren Maya Section 6</h1>
            </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <img 
                  src="@/assets/images/inprogress/tren-maya-tramo-6/01.webp"
                  class="img-fluid"
                >
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                  <h3>Project Description</h3>
                  <p>The purpose of the contract is the Catenary Cable Laying and Tensioning 
                    Service for Section 6 South of the Maya Train, consisting of:
                  </p>
                  <ul>
                    <li>Installation of feeder cables on substation porticos.</li>
                    <li>Installation of compensation equipment using pulleys and counterweights.
                    </li>
                    <li>Laying and tensioning of supporting wire and contact wire.</li>
                    <li>Installation of section insulators.</li>
                    <li>Installation of mechanical disconnectors.</li>
                    <li>Laying and tensioning of ACSR LAS280 feeder.</li>
                    <li>Laying and tensioning of ACSR LA110 return cable.</li>
                    <li>Cable laying and tensioning service for the catenary, installation of 
                      compensation equipment, and mechanical disconnectors.
                    </li>
                  </ul>
                  <h3 class="mb-3">The Project in figures</h3>
                  <div class="row align-content-center text-center justify-content-center">
                    <div class="col-lg-4 col-7 mb-4">
                      <h2 class="m-0 fw-bold">256 KM</h2>
                      <h3>SECTION 6</h3>
                    </div>
                    <div class="col-lg-5 col-10 align-content-center">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_SEDENA.png"
                        class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="slt-fase-2">
          <div class="container">
            <!-- Título -->
            <div class="row">
              <div class="d-flex text-start bg-dark-50-entia align-content-center">
                <h1 class="bullet ms-5 my4 ibm-plex-sans fw-bold color-white-entia">
                  359 SLT Link Congestion Solution Transmission GCR Northwest North Phase 2 Stage 3
                </h1>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 align-content-center justify-content-center my-4">
                <img 
                  src="@/assets/images/inprogress/slt-fase-2/01.webp"
                  class="img-fluid"
                >
              </div>
              <div class="col descripcion">
                <div class="col m-5">
                <h3>Project Description</h3>
                <p>The purpose of the contract is the execution of all necessary works for the 
                  construction and installation of the Project '359 SLT Link Congestion Solution Transmission GCR 
                  Northwest North Phase 2 Stage 3' including:
                </p>
                <ul>
                  <li>Construction and installation of 2 transmission lines:
                    <ul>
                      <li>Mazatlán II - Tepic II TL: 400 kV  Voltage, DC 2H Towers per phase (257.6 km).</li>
                      <li>Tepic II - Cerro Blanco TL: 400 kV Voltage, DC 2H Towers per phase (42.6 km).</li>
                    </ul>
                  </li>
                  <li>Expansion of 3 substations:
                    <ul>
                      <li>Mazatlán II Substation: One Line output bay + 3 reactors at 400 kV.</li>
                      <li>Tepic II Substation: One 400 kV Line Bay.</li>
                      <li>Cerro Blanco Substation: One 400 kV Line Bay.</li>
                    </ul>
                  </li>
                </ul>
                <h3>The Project in figures</h3>
                <div class="row align-content-center text-center justify-content-center">
                  <div class="col-lg-4 col-6">
                    <h2 class="m-0 fw-bold">510</h2>
                    <h2 class="m-0 fw-bold fs-4">DAYS</h2>
                  </div>
                  <div class="col-lg-4 col-6 align-content-center">
                    <h2 class="m-0 fw-bold">300 KM</h2>
                  </div>
                  <div class="col-lg-3 col-6 mt-3">
                      <img 
                        src="@/assets/images/inprogress/logos/LOGO_CFE.png"
                        class="img-fluid">
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer msg="Got a project in mind?" />
    </div>
</template>
<script>
import Footer from "@/en/components/common/Footer";
import ArrowService from "@/en/components/common/ArrowService";

export default {
  name: "Ejecucion",
  components: {
    Footer,
    ArrowService,
  }, 
  data(){
    return{
      proyectos:[
      {
        title:'Tren interurbano México-Toluca',
        image: '01'},
      {
        title:'Suministro',
        image: '02'
      },
      {
        title: 'Catenaria del tren maya',
        image: '03'
      },
      {
        title: 'Sistemas de Energía y SETE',
        image: '04'
      },
      {
        title: 'Instalacion de la catenaria tramo 5',
        image: '05'
      },
      {
        title: 'Telemando de Seccionadores de Catenaria',
        image: '06'
      },
      {
        title: 'Solución Congestion de Enlaces',
        image: '07'
      },
      {
        title: 'Catenaria del tren Maya Tramo 6',
        image: '08'
      }
      ],
    }
  },
  methods: {
    scrollBehavior(id) {
      let element = document.getElementById(id);
      let offset = 140;
      //const offset = document.body.clientWidth <= 992 ? 120 : 140; 
      if (offset == 992){
        offset = 120;
      }
      const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: elementPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>