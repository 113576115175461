<template>
  <header class="sticky-top bg-white" id="header-entia">
    <div
      class="
        d-flex
        flex-wrap
        justify-content-center
        py-3
        container
        d-none d-xl-flex
      "
    >
      <router-link
        to="/en"
        class="
          d-flex
          align-items-center
          mb-3 mb-md-0
          me-md-auto
          text-dark text-decoration-none
        "
        exact
      >
        <img
          src="@/assets/images/common/entia-logo.svg"
          alt="logo-entia"
          class="img-fluid"
          width="140"
        />
      </router-link>

      <ul class="nav nav-pills justify-content-center align-items-center">
        <li class="nav-item">
          <router-link to="/en" class="nav-link" exact>Home</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/en/about" class="nav-link">About</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link to="/en/services" class="nav-link">Services</router-link>
        </li> -->
        <li class="nav-item">
          <router-link to="/en/portfolio" class="nav-link"
            >Portfolio</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/en/InProgress" class="nav-link"
            >In Progress</router-link
          >
          </li>
        <li class="nav-item">
          <router-link to="/en/contact-us" class="nav-link m-0 me-lg-3 me-xl-5"
            >Contact Us</router-link
          >
        </li>
      </ul>
      <ul class="nav nav-pills justify-content-center align-items-center">
        <li>
          <a
            class="nav-link me-2 ms-auto"
            @click="goToPath('es')"
            :class="
              $route.path.split('/')[1] == 'es'
                ? 'router-link-exact-active router-link-active'
                : ''
            "
            >ESP</a
          >
        </li>
        <li>
          <a
            class="nav-link me-0"
            @click="goToPath('en')"
            :class="
              $route.path.split('/')[1] == 'en'
                ? 'router-link-exact-active router-link-active'
                : ''
            "
            >ENG</a
          >
        </li>
      </ul>
      <img
          src="@/assets/images/common/gptw.png"
          alt="logo-entia"
        />
    </div>

    <nav
      class="navbar navbar-expand-xl navbar-light bg-white px-0 py-3 d-xl-none"
    >
      <div class="container-fluid">
        <router-link class="navbar-brand py-2 px-3" to="/en">
          <img
            alt="entia-logo"
            class="img-fluid"
            src="@/assets/images/common/entia-logo.svg"
            exact
          />
        </router-link>
      <img
          src="@/assets/images/common/gptw.png"
          alt="logo-entia"
        />
        <button
          id="menuMobileButton"
          class="navbar-toggler my-2 mx-3"
          type="button"
          data-bs-toggle="collapse"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="toggleMenu"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="ps-0 my-2 mt-lg-0 header-items">
            <li class="nav-item">
              <a
                @click="$router.push('/en').catch(() => {})"
                class="nav-link"
                :class="$route.name == 'HomeEN' ? 'router-link-active' : ''"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                Home
              </a>
            </li>
            <li class="nav-item">
              <a
                @click="$router.push('/en/about').catch(() => {})"
                class="nav-link"
                :class="$route.name == 'AboutEN' ? 'router-link-active' : ''"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                About
              </a>
            </li>
            <!-- <li class="nav-item">
              <a
                @click="$router.push('/en/services').catch(() => {})"
                class="nav-link"
                :class="$route.name == 'ServicesEN' ? 'router-link-active' : ''"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                Services
              </a>
            </li> -->
            <li class="nav-item">
              <a
                @click="$router.push('/en/portfolio').catch(() => {})"
                class="nav-link"
                :class="
                  $route.name == 'PortfolioEN' ? 'router-link-active' : ''
                "
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                Portfolio
              </a>
            </li>
            <li class="nav-item">
              <a
                @click="$router.push('/en/InProgress').catch(()=>{})"
                class="nav-link"
                :class="$route.name == 'InProgress' ? 'router-link-active' : ''"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
              In Progress
            </a>
            </li>
            <li class="nav-item">
              <a
                @click="$router.push('/en/contact-us').catch(() => {})"
                class="nav-link"
                :class="$route.name == 'ContactEN' ? 'router-link-active' : ''"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                Contact Us
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link d-inline-block me-2 ms-auto"
                @click="goToPath('es')"
                :class="
                  $route.path.split('/')[1] == 'es'
                    ? 'router-link-exact-active router-link-active'
                    : ''
                "
                >ESP</a
              >
              <a
                class="nav-link d-inline-block me-0"
                @click="goToPath('en')"
                :class="
                  $route.path.split('/')[1] == 'en'
                    ? 'router-link-exact-active router-link-active'
                    : ''
                "
                >EN</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  methods: {
    toggleMenu() {
      if (!document.getElementById("navbarNav").classList.contains("show")) {
        document.querySelector("#menuMobileButton").ariaExpanded = "true";
        document.getElementById("navbarNav").classList.add("show");
      } else {
        document.querySelector("#menuMobileButton").ariaExpanded = "false";
        document.getElementById("navbarNav").classList.remove("show");
      }
    },
    goToPath(language) {
      const pathname = window.location.pathname;

      if (language === "es") {
        if (pathname === "/en" || pathname === "/en/") {
          window.location.href = "/es";
        } else if (pathname === "/en/about" || pathname === "/en/about/") {
          window.location.href = "/es/acerca";
        } else if (
          pathname === "/en/services" ||
          pathname === "/en/services/"
        ) {
          window.location.href = "/es/servicios";
        } else if (
          pathname === "/en/portfolio" ||
          pathname === "/en/portfolio/"
        ) {
          window.location.href = "/es/portafolio";
        } else if (
          pathname === "/en/contact-us" ||
          pathname === "/en/contact-us/"
        ) {
          window.location.href = "/es/contacto";
        } else if(
          pathname === "/en/InProgress" ||
          pathname === "/en/InProgress/"){
            window.location.href = "/es/proyectos-en-progreso";
        } else if (
          pathname === "/en/work-for-us" ||
          pathname === "/en/work-for-us/"
        ) {
          window.location.href = "/es/trabaja-con-nosotros";
        } else if (
          pathname === "/en/portfolio/railway-infrastructure" ||
          pathname === "/en/portfolio/railway-infrastructure/"
        ) {
          window.location.href = "/es/portafolio/infraestructuras-ferroviarias";
        } else if (
          pathname ===
            "/en/portfolio/electricity-transmission-and-electrical-line-distribution" ||
          pathname ===
            "/en/portfolio/electricity-transmission-and-electrical-line-distribution/"
        ) {
          window.location.href =
            "/es/portafolio/lineas-de-transmision-y-distribucion-electrica";
        } else if (
          pathname === "/en/portfolio/roads-and-building-projects" ||
          pathname === "/en/portfolio/roads-and-building-projects/"
        ) {
          window.location.href = "/es/portafolio/carreteras-y-edificacion";
        } else if (
          pathname === "/en/portfolio/power-generation-and-the-environment" ||
          pathname === "/en/portfolio/power-generation-and-the-environment/"
        ) {
          window.location.href =
            "/es/portafolio/generacion-de-energia-y-medio-ambiente";
        }
      } else {
        if (pathname === "/es" || pathname === "/es/") {
          window.location.href = "/en";
        } else if (pathname === "/es/acerca" || pathname === "/es/acerca/") {
          window.location.href = "/en/about";
        } else if (
          pathname === "/es/servicios" ||
          pathname === "/es/servicios/"
        ) {
          window.location.href = "/en/services";
        } else if (
          pathname === "/es/portafolio" ||
          pathname === "/es/portafolio/"
        ) {
          window.location.href = "/en/portfolio";
        } else if(
          pathname === "/es/proyectos-en-progreso" ||
          pathname === "/es/proyectos-en-progreso/"){
            window.location.href = "/en/InProgress";
        }
        else if (
          pathname === "/es/contacto" ||
          pathname === "/es/contacto/"
        ) {
          window.location.href = "/en/contact-us";
        } else if (
          pathname === "/es/trabaja-con-nosotros" ||
          pathname === "/es/trabaja-con-nosotros/"
        ) {
          window.location.href = "/en/work-for-us";
        } else if (
          pathname === "/es/portafolio/infraestructuras-ferroviarias" ||
          pathname === "/es/portafolio/infraestructuras-ferroviarias/"
        ) {
          window.location.href = "/en/portfolio/railway-infrastructure";
        } else if (
          pathname ===
            "/es/portafolio/lineas-de-transmision-y-distribucion-electrica" ||
          pathname ===
            "/es/portafolio/lineas-de-transmision-y-distribucion-electrica/"
        ) {
          window.location.href =
            "/en/portfolio/electricity-transmission-and-electrical-line-distribution";
        } else if (
          pathname === "/es/portafolio/carreteras-y-edificacion" ||
          pathname === "/es/portafolio/carreteras-y-edificacion/"
        ) {
          window.location.href = "/en/portfolio/roads-and-building-projects";
        } else if (
          pathname ===
            "/es/portafolio/generacion-de-energia-y-medio-ambiente" ||
          pathname === "/es/portafolio/generacion-de-energia-y-medio-ambiente/"
        ) {
          window.location.href =
            "/en/portfolio/power-generation-and-the-environment";
        }
      }
    },
  },
};
</script>

<style></style>
